header{
    background-color: #ffffff96;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    padding: 0 2rem;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

header .navbar-items .nav-links{
    margin: 0 2rem;
    color: #0a0a0a;
    text-decoration: none;
    padding: 15px 30px;
    border-radius: 20px;
    transition: 0.5s;
    font-size: 1.1rem
}

header .navbar-items .nav-links:hover{
    color: #F5F5F5;
    background-color: #F24E1E;
}

header .nav-btn{
    padding: 5px;
    cursor: pointer;
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
    font-size: 1.5rem;
    background-color: #9751171f;
    color: #F3FCFA;
    border-radius: 5px;
}

@media only screen and (max-width: 1024px){

    header .nav-btn{
        visibility: visible;
        opacity: 1;
    }

    header .navbar-items{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        transition: 1s;
        transform: translateY(-100vh);
        background-color: #F24E1E;
    }

    header .navbar-items .nav-links{
        color: #F5F5F5;
    }

    header .navbar-items .nav-links:hover{
        color: #F24E1E;
        background-color: #F5F5F5;
    }

    header .responsive_nav{
        transform: none;
    }

    .navbar-items .nav-close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

}