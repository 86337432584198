.fchefchaouen-small-tour .fchefchaouen-small-tour-heading{
    background-image: url(../../../../assets/fessouk.jpg);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding: 180px 0;
    text-align: center;
    color: #F5F5F5
}

.fchefchaouen-small-tour .fchefchaouen-small-tour-heading h3{
    font-size: 2.5rem;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
 }

 .service-info{
    margin: 0;
    padding: 5%;
    background-color: #e0e0e0;
    display: flex;
 }

 .service-info-sections{
    display: flex;
    margin: 0 auto;
    align-items: center;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
 }

 .hightlights{
    margin: 4%;
    background-color: #F5F5F5;
    padding: 2%
 }

 .hightlights .hightlight-heading{
    display: flex;
    align-items: center;
 }

 .hightlights .hightlight-heading h6{
    font-size: 1.2rem;
    color: #F24E1E;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
 }

 .hightlight-list ul{
    margin: 2%;
    list-style: square;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
 }

 .hightlight-list li{
    margin: 2% 0;
 }

 .description{
    margin: 4%;
    background-color: #F5F5F5;
    padding: 2%;
 }

 .description .description-heading{
    display: flex;
    align-items: center;
    margin: 0 0 2.5%;
 }

 .description .description-heading h6{
    font-size: 1.2rem;
    color: #F24E1E;
    margin: 0 1%;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
 }

 .description-content p{
    line-height: 1.2;
    font-size: 0.9rem;
    font-family: 'osans-medium', Lato, Arial, sans-serif;
    line-height: 2;
 }

 .itinary{
    margin: 4%;
    background-color: #F5F5F5;
    padding: 1%;
    padding: 2%
 }

 .itinary-heading{
    display: flex;
    align-items: center;
 }

 .itinary .itinary-heading h6{
    font-size: 1.2rem;
    color: #F24E1E;
    margin: 0 1%;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
 }

.day{
    margin: 2%;
    background-color: #F24E1E;
    padding: 2%;
 }

.day p{
    margin: 1% 0;
    color: #F5F5F5;
    text-align: justify;
    width: 100%;    

 }

 .day-title{
    background-color: #e46f4c;
    width: fit-content;
    height: fit-content;
    padding: 1%;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
 }


 .tour-detail{    
    margin: 4%;
    background-color: #F5F5F5;
    padding: 2%
}

.tour-detail .tour-detail-heading{
    display: flex;
    align-items: center;
}

.tour-detail .tour-detail-heading h6{
    font-size: 1.2rem;
    color: #F24E1E;
    margin: 0 1%;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
 }

 .tour-heading-content ul{
    margin: 2% 0;
    background-color: #d66c4c1f;
    padding: 1%
 }

 .tour-heading-content ul li{
    display: flex;
    align-items: center;
    margin: 2%;
    list-style: none;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
 }

 .tour-heading-content ul p{
    margin: 0 1%;
 }

 @media only screen and (max-width: 1024px){

    .service-info{
        display: block;
        width: 100%;
     }

     .service-info-sections{
        display: block;
        text-align: center;
        margin: 4% 0
     }

     .day{
        margin: 5% 0;
     }

     .description .description-heading{
      padding: 2%;
      margin: 0 0 2%;
   }

     .description .description-content{
      padding: 1%;
   }
  
   .hightlights .hightlight-heading{
      padding: 2%;
      margin: 0 0 2%;
   }

   .hightlights .hightlight-list ul li{
      margin: 5% 10%
   }

   .tour-heading-content ul li{
      padding: 2%;
   }

   .tour-heading-content ul li p{
      margin:  0 5%
   }
   
    
}