.marrakech .marrakech-heading{
    background-image: url(../../../assets/Marrakech-heading.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 180px 0;
    text-align: center;
    color: #F5F5F5
}

.marrakech .marrakech-heading h3{
   font-size: 2.5rem;
   font-family: 'osans-xbold', Lato, Arial, sans-serif;
}

.marrakech .marrakech-packs{    
    background-color: #f24f1e25;
    padding: 20px;
    margin: 20px 10px
}

.marrakech .marrakech-packs .marrakech-first-line{
    display: flex;
    padding: 10px;
}

.marrakech-pack{
    background-color: #F5F5F5;
    margin: 10px auto;
    display: flex;
    width: 50%
}

.marrakech-content{
    margin: 16px
}

.marrakech-content h6{
    font-size: 1.4rem;
    margin: 5px 0 20px;
    color:#F24E1E;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
}

.marrakech-content p{
    font-size: 1.1rem;
    width: 100%;
    margin: 0 0 20px;
    font-family: 'osans-medium', Lato, Arial, sans-serif;
}

.marrakech-pack-detail-btn{
    padding: 18px;
    cursor: pointer;
    border: 1px solid #F24E1E;
    background-color: #F24E1E;
    color: #F5F5F5;
    transition: 0.4s;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
}

.marrakech-pack-detail-btn:hover{
    background-color: #f24f1ece;
    transition: 0.4s
}

@media only screen and (max-width: 1024px){
    .marrakech .marrakech-packs .marrakech-first-line{
        display: block;
    }

    .marrakech-pack{
        background-color: #F5F5F5;
        margin: 10px auto;
        display: block;
        width: 80%;
        text-align: center;
    }

    .marrakech-photo img{
        width: 100%;
        height: 100%
    }

    .marrakech-content{
        margin: 0;
    }

    .marrakech-content p{
        font-size: 1.1rem;
        width: 70%;
        margin: 1rem auto
    }

    .marrakech-pack-detail-btn{
        width:100%
    }
    
}