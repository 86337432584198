.values{
    background-color: #f24f1e2a;
    padding: 10px;
    margin: 100px 0;
    text-align: center;
}

.values h6{
    font-size: 2.5rem;
    color: #F5F5F5;
    background-color: #F24E1E;
    width: 100%;
    margin: 0 auto;
    padding: 1%;
    position: relative;
    bottom: 50px;
    box-shadow: 0 0 10px #010101;
    border-radius: 5%;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
}

.values .first-line, .values .second-line{
    display: flex;
}

.values .first-line small, .values .second-line small{
    font-size: 2rem;
    font-weight: bold;
}

.first-line .first-elemnt, .first-line .second-elemnt, .second-line .first-elemnt, .second-line .second-elemnt{
    padding: 50px;
    width: 100vw;
}

.values .first-line p, .values .second-line p{
    font-size: 1.5rem;
    margin: 5% auto;
    font-family: 'osans-light', Lato, Arial, sans-serif;
}

.value-title{
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
}

@media only screen and (max-width: 1024px){

    .values .first-line, .values .second-line{
        display: block;
    }
}

