.container{
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.intro{
    text-align: center;
    background-image: url(../../assets/intro.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color :#F5F5F5;
    padding: 180px 0;
    color: #F5F5F5;
}

.intro h1{
    font-size: 4rem;
    margin: 1rem 0;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
}

.intro h3{
    font-size: 2rem;
    margin: 1rem 0;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

.welcome-card{
    display: flex;
    background-color: #0a0a0a;
    margin: 0;
    box-shadow: 0px 10px 10px rgba(49, 49, 49, 0.329);
    box-shadow: 0 20px 20px #55514f6e;
}

.welcome-message{
    padding: 40px;
    margin: 15px 0;
}

.welcome-message p{
    color: #F5F5F5;
    font-size: 1.4rem;
    font-family: 'osans-light', Lato, Arial, sans-serif;
    margin: 0 0 25px 0;
    font-style: oblique;
}

.aboutus-btn{
    margin: 10px 0;
    padding: 15px 60px;
    background-color: transparent;
    border: 5px solid #F5F5F5;
    color: #F5F5F5;
    font-size: 1.2rem;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.671),inset 0 0 5px rgba(0, 0, 0, 0.671);
    transition: 1s;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
    width: 100%;
}

.aboutus-btn:hover{
    background-color: #F5F5F5;
    color: rgb(27, 27, 27);
    transition: 1s;
    font-weight: bold;
    border: 5px solid #F5F5F5;
}

.home-services{
    background-color: #E3E3E3;
    padding: 30px;
    margin: 120px 0;
    text-align: center;
}

.home-services .home-services-title{
    text-align: center;
}

.home-services h3{
    font-size: 3rem;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

.home-services-section-one{
    display: flex;
    padding: 20px;
}

.home-services-section-one .home-services-cards{
    margin: 40px auto;
    background-color: #F5F5F5;
    font-family: 'osans-regular', Lato, Arial, sans-serif;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.39)
}

.home-services-section-one .home-services-cards .home-services-card-title{
    padding: 5px
}

.home-services-section-one .home-services-cards .home-services-card-title small{
    font-size: 1.5rem;
    color: #F24E1E;
    font-family: 'osans-medium', Lato, Arial, sans-serif;
}

.home-services-section-one .home-services-cards .btn-services-details{
    padding: 10px 50px;
    background-color: #F24E1E;
    color: #F5F5F5;
    border: 1px solid #F5F5F5;
    margin: 10px 10px 20px 10px;
    cursor: pointer;
    font-family: 'osans-medium', Lato, Arial, sans-serif;
}

.load-services-btn{
    padding: 25px 50px;
    background-color: transparent;
    border: 2px solid #F24E1E;
    color: #F24E1E;
    cursor: pointer;
    transition: 1s;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
    text-align: center;
}

.load-services-btn:hover{
    background-color: #F24E1E;
    color: #F5F5F5;
    transition: 1s;
}



@media only screen and (max-width: 1024px){

    .intro{
        width: 100%;
        margin: 0 auto
    }

    .intro h1{
       width: 100%;
       font-size: 2.5rem
    }

    .intro h3{
        width: 50%;
        margin: 0 auto;
        font-size: 2rem
    }

    .welcome-card{
        display: block;
        text-align: center;
        width: 100%;
        margin: 5% auto
    }

    .home-services{
        width: 100%;
        margin: 0 auto;
    }
  
    .home-services-section-one{
        display: block;
        width: 80%;
        margin: 0 auto
    }

    .home-services-cards img{
        width: 100%;
        height: 100%;
    }

    .welcome-message{
        margin: 0;
    }

}

@media only screen and (max-width: 600px){

    .intro{
        width: 100%;
        margin: 0 auto;
    }

    .intro h1{
       width: 100%;
       font-size: 1.98rem;
    }

    .intro h3{
        width: 50%;
        margin: 0 auto;
        font-size: 1.2rem
    }

    .aboutus-btn{
        margin: 10% 0;
        padding: 15px 30px;
    }

    .load-services-btn{
        padding: 20px 30px;
    }

    .welcome-card{
        margin: 0;
    }


}