.team{
    background-image: url(../../assets/team_background.png);
    padding: 30px 10px;
    color: #F5F5F5;
}

.team .team-title{
    text-align: center;
    padding: 20px;
    margin: 0 0 25px;
}

.team .team-title h6{
    font-size: 2.5rem;
    margin: 0 0 30px;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
}
 
.team .team-title p{
    font-size: 1.4rem;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

.team .members{
    display: flex;
    text-align: center;
    justify-content: space-around;
}


.team .members aside{
    padding: 0 20px
}

.team .members .members-name{
    font-size: 1.4rem;
    width: 100%;
    margin: 0 auto;
    background-color: #310B0B;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
}

.team .members .job-title{
    font-size: 1.2rem;
    font-family: 'osans-medium', Lato, Arial, sans-serif;
    font-style: italic;
}

.member-num{
    display: flex;
    margin: 10px auto;
    justify-content: center;
    font-family: 'osans-light', Lato, Arial, sans-serif;
}

@media only screen and (max-width: 1024px){

    .team .members{
        display: block;
        text-align: center;
    }

    .member-num{
        display: block;
        margin: 10px auto;
    }

    .ahmed, .youssef{
        margin: 50px 0
    }

    .team .members img{
        width: 100%;
        height: 100%;
    }
}