@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.testimony-carousel{
    width: 100%;
    margin: 0 auto;
    height: 500px;
    padding: 5%
}

.slide img{
    margin: 0 auto;
    box-shadow: 0px 5px 10px #8a8a8ae1;
    height: fit-content;
}

.slide{
    transform: scale(0.2);
    transition: transform 300ms;
    opacity: .5;
    padding: 10px;
}

.activeSlide{
    transform: scale(1);
    opacity: 1;
}

.arrow{
    background-color: #F24E1E;
    position: absolute;
    cursor: pointer;
    z-index: 10;
    padding: 10px;
    border-radius: 50%;

}

.arrow svg{
    transition: color 200ms;
    color: #F5F5F5
}

.arrow svg:hover{
    color: #FFFFFF
}

.next{
    right: 0%;
    top: 50%;
}

.prev{
    left: 0%;
    top: 50%;
}





