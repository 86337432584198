.gallery{
    display: flex;
    background-color: #010101;
    padding: 40px;
    color: #F5F5F5
}

.gallery-left{
    margin: 0 auto;
}

.gallery-thumbnail{
    border-radius:  5%;
    box-shadow: 0 10px 20px rgba(94, 94, 104, 0.5)
}

.gallery-right{
    padding: 40px;
    margin: 0 0 0 20px
}

.gallery-right .gallery-title{
    display: flex
}

.gallery-right h6{
    font-size: 2.2rem;
    margin: 0 0 20px 10px;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

.gallery-right p{
    font-size: 1.5rem;
    width: 80%;
    margin: 0 0 20px 0;
    font-family: 'osans-light', Lato, Arial, sans-serif;
    line-height: 1.6;
}

.gallery-right .modal-btn{
    padding: 15px 120px;
    background-color: transparent;
    color: #F5F5F5;
    border: 1px solid #F5F5F5;
    transition: 0.2s;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

.gallery-right .modal-btn:hover{
    background-image: linear-gradient(to right, #020202, #11111186);
    transition: 0.8s;
    cursor: pointer;
    padding: 20px 120px;
}

@media only screen and (max-width: 1024px){
    .gallery{
        display: block;
        text-align: center;
    }

    .gallery-left img{
        width: 100%;
    }

    .gallery-right .gallery-title{
        display: block
    }

    .gallery-right p{
        margin: 0 auto;
    }

    .gallery-right .modal-btn{
        padding: 4% 8%;
        margin: 5% 0 0
    }
}

@media only screen and (max-width: 600px){
    
    .gallery-right .modal-btn{
        padding: 8%;
        margin: 20% 0 0;
    }
}