.services-packs{
    padding: 20px;
}

.services-packs .pack{
    display: flex;
    background-color: #F5F5F5;
    box-shadow: 0 5px 10px rgb(148, 148, 148);
    margin: 20px
}

.services-packs .pack .content{
    padding: 20px;
}

.services-packs .pack .content h6{
    font-size: 2rem;
    margin: 5px 0 10px;
    color: #CF0A0A;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

.services-packs .pack .content p{
    font-size: 1.5rem;
    margin: 5px 0 10px;
    font-family: 'osans-light', Lato, Arial, sans-serif;
}

.services-packs .pack .content .pack-detail-btn{
    padding: 25px 60px;
    border: 1px solid #CF0A0A;
    color: #F5F5F5;
    background-color: #CF0A0A;
    cursor: pointer;
    transition: 0.4s;
    margin: 30px 0 0;
    width: 100%;
    text-align: center;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

.services-packs .pack .content .pack-detail-btn:hover{
    background-color: #e64040;
    color: #F5F5F5;
    transition: 0.4s
}

@media only screen and (max-width: 1024px){

    .services-packs .pack{
        display: block;
        width: 80%;
        margin: 5% auto;
        text-align: center;
    }

    .services-packs .pack .photo img{
        width: 100%;
        height: 100%
    }

    .services-packs .pack .content .pack-detail-btn{
        padding: 5% 10%;
        border: 1px solid #CF0A0A;
        color: #F5F5F5;
        background-color: #CF0A0A;
        cursor: pointer;
        transition: 0.4s;
        margin: 30px 0 0;
        width: 100%;
        text-align: center;
    }

    .services-packs .pack .content h6{
        font-size: 1.5rem;
    }
    
}

 
 