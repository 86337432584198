.services-intro{
    background-image: url(../../../assets/services.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color :#F5F5F5;
    padding: 160px 0;
    text-align: center;
    overflow: hidden;
}

.services-intro h3{
    font-size: 3rem;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
    color: #F5F5F5
}

.services-intro h5{
    font-size: 2.4rem;
    font-family: 'osans-regular', Lato, Arial, sans-serif;
    color: #F5F5F5;
    margin: 2%;
    font-style: italic;
}