.contact{
    background-color: #310B0B;
    padding: 20px;
    border: 1px solid #A34A28
}

.contact-title{
    text-align: center;
    color: #F5F5F5;
    margin: 30px 0;
    padding: 20px;
}

.contact-title h3{
    margin: 22px 0 0;
    font-size: 1.6rem;
    display: flex;
    flex-direction: row;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
}

.contact-title h3:before, .contact-title h3:after{
    content: "";
    flex: 1 1;
    border-bottom: 2px solid #A34A28;
    margin: auto;
}

.contact-title h3:before{
    margin-right: 10px
}

.contact-title h3:after{
    margin-left: 10px
}

.contact-info{
    display: flex;
    padding: 20px;
    margin: 0 0 40px
}

.contact-info .info-left{ 
    margin: 0 20px;
    width: 100%;
    border-left: 1px solid #A34A28;
    font-family: 'osans-regular', Lato, Arial, sans-serif;
}

.contact-info .info-left h5{
    font-size: 1.4rem;
    color: #F5F5F5;
    margin: 0 20px;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

.contact-info .info-left .location, .contact-info .info-left .mail, .contact-info .info-left .phone{
    color: #F5F5F5;
    margin: 30px 20px 10px;
}

.contact-info .info-left small{
    margin: 0 10px
}

.contact-info .info-right{
    text-align: center;
    margin: 0 150px 0 200px;
    width: 1000px;
    border-left: 1px solid #A34A28;
}

.contact-info .info-right h5{
    font-size: 1.4rem;
    color: #F5F5F5;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

.contact-info .info-right .social-credentials{
    padding: 20px
}

.contact-info .info-right .social-credentials .social-links{
    margin: 5px 17px;
}

@media only screen and (max-width: 1024px){

    .contact-info{
        display: block;
        text-align: center;
    }

    .contact-info .info-left, .contact-info .info-right{
        margin: 10% auto;
        width: 50%
    }

}

@media only screen and (max-width: 700px){

    .contact-info .info-left{
        display: block;
        margin: 0 0 5%;
        width: 100%
    }

}