.add-modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.add-modal-content{
    width: 80%;
    background-color: #F5F5F5 ;
    height: 100%;
    overflow: scroll;
}

.add-modal-header{
    padding: 2% 4%;
    background-color: #F24E1E;
    align-items: center;
    justify-content: space-between;
}

.add-modal-header{
    display: flex;
}

.add-modal-body{
    padding: 5%
}


@media only screen and (max-width: 600px){

    .add-modal-content{
        width: 100%;
        background-color: #F5F5F5 ;
        height: 100%
    }
    
}