.terms-content{
    padding: 10%;
    background-color: #030303;
}

.terms-content h4{
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
    font-size: 2rem;
    color: #F24E1E
}

.terms-content h6{
    font-family: 'osans-sbold', Lato, Arial, sans-serif;
    font-size: 1.2rem;
    color: #F24E1E;
    margin: 4% 0 2%
}

.terms-content ul li{
    font-family: 'osans-sbold', Lato, Arial, sans-serif;
    font-size: 1.2rem;
    color: #F5F5F5;
    list-style: none
}

.terms-content ul li a{
    font-family: 'osans-bold', Lato, Arial, sans-serif;
    color: #F24E1E;
}