footer .main-footer{
    background-color: #0f0f0f;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    border: 1px solid #A34A28
}

footer .main-footer .footer-cat{
    padding: 20px;
    margin: 0
}

footer .main-footer .footer-cat{
    padding: 50px;
}

footer .main-footer .footer-cat .footer-links{
    margin: 0 30px;
    font-size: 1.2rem;
    color: #F5F5F5;
    text-decoration: none;
    font-family: 'osans-medium', Lato, Arial, sans-serif;
}

footer .main-footer .footer-cat .footer-links:hover{
    border-bottom: 1px solid #A34A28;
}

footer .copywrite{
    background-color: #080808;
    text-align: center;
    padding: 20px;
    color: #F5F5F5;
    font-family: 'osans-light', Lato, Arial, sans-serif;
    font-weight: bold
}


@media only screen and (max-width: 1024px){

    footer .main-footer{
        display: block;
        text-align: center;
    }

    footer .main-footer .footer-cat .footer-links{
        margin: 2%;
        width: 50%
    }

}

@media only screen and (max-width: 600px){

    footer .main-footer .footer-cat .footer-links{
        font-size: 0.7rem;
        display: inline-block;
        width: 100%;
        margin: 5% 0;
    }

}