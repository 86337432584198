.credits{
    display: flex;
    align-items: center;
}

.credits section{
    background-color: #010101;
    width: 100%;
    height: 100vh;
    padding: 1% 5%;
    text-align: center;
}

.credits section h6{
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
    color: #3a3a94;
    font-size: 2rem
}

.credits section p{
    font-family: 'osans-regular', Lato, Arial, sans-serif;
    color: #F5F5F5;
    font-size: 1rem;
    margin: 2% 0;
}

.credits section ul li{
    font-family: 'osans-light', Lato, Arial, sans-serif;
    color: #F5F5F5;
    font-size: 1rem;
    list-style: none;
    margin: 2% 0
}

.credits section .links-devs{
    text-decoration: none;
    background-color: rgb(110, 85, 138);
    padding: 2% 5%;
    position: relative;
    top: 2rem;
    color: #F5F5F5;
    transition: 0.5s;
}

.credits section .links-devs:hover{
    transition: 0.5s;
    background-color: rgb(111, 69, 158);
    padding: 4% 8%;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
    box-shadow: 0 10px 20px #3a3a94
}


@media only screen and (max-width: 1024px){

    .credits{
        display: block;
    }
    

}