@font-face{
    font-family: 'osans-xbold';
    src: url(../src/res/OpenSans-ExtraBold.ttf)
}

@font-face{
    font-family: 'osans-bold';
    src: url(../src/res/OpenSans-Bold.ttf)
}

@font-face{
    font-family: 'osans-medium';
    src: url(../src/res/OpenSans-Medium.ttf)
}

@font-face{
    font-family: 'osans-regular';
    src: url(../src/res/OpenSans-Regular.ttf)
}

@font-face{
    font-family: 'osans-sbold';
    src: url(../src/res/OpenSans-SemiBold.ttf)
}

@font-face{
    font-family: 'osans-xbold';
    src: url(../src/res/OpenSans-ExtraBold.ttf)
}

@font-face{
    font-family: 'osans-light';
    src: url(../src/res/OpenSans-Light.ttf)
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: #DFDDC7;
    overflow-x: hidden
}