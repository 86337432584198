.presentation{
    padding: 10px;
    margin: 30px
}

.presentation .presentation-title{
    display: flex;
    padding: 20px;
    border-left: 10px solid #D83C11;
    font-family: 'osans-xbold', Lato, Arial, sans-serif;
}

.presentation .presentation-title h3{
    font-size: 2rem;
    margin: 0 0 0 20px;
}

.presentation .presentation-title h3 span{
    color: #D83C11;
}

.presentation .morocco, .presentation .wandercasablanca{
    margin: 30px 0;
    background-color: #110f0f18;
    padding: 20px
}

.presentation .morocco h6, .presentation .wandercasablanca h6{
    font-size: 1.5rem;
    color: #D83C11;
    margin: 0 0 30px 0;
    font-family: 'osans-bold', Lato, Arial, sans-serif;
}

.presentation .morocco p, .presentation .wandercasablanca p{
    text-align: justify;
    margin: 0 0 20px 0;
    font-size: 0.9rem;
    font-family: 'osans-medium', Lato, Arial, sans-serif;
    line-height: 2;
}